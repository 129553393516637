const DocumentsCoordonnateurTableData = [
    {
        id: 1,
        titre: 'Vel sed in nisl ac sit.',
        date_ajout: '12 mars 2021',
    },
    {
        id: 2,
        titre: 'Vel sed in nisl ac sit.',
        date_ajout: '12 mars 2021',
    },
    {
        id: 3,
        titre: 'Vel sed in nisl ac sit.',
        date_ajout: '12 mars 2021',
    },
    {
        id: 4,
        titre: 'Vel sed in nisl ac sit.',
        date_ajout: '12 mars 2021',
    },
];

export default DocumentsCoordonnateurTableData;
