const LiveEventTableData = [
    {
        id: 1,
        libelle: 'Curabitur volutpat lectus velit.',
        id_reunion: '12380E9P24H',
        date: '20/12/2021',
        heure: '12H00- 14H00',
    },
    {
        id: 2,
        libelle: 'Curabitur volutpat lectus velit.',
        id_reunion: '12380E9P24H',
        date: '20/12/2021',
        heure: '12H00- 14H00',
    },
    {
        id: 3,
        libelle: 'Curabitur volutpat lectus velit.',
        id_reunion: '12380E9P24H',
        date: '20/12/2021',
        heure: '12H00- 14H00',
    },
    {
        id: 4,
        libelle: 'Curabitur volutpat lectus velit.',
        id_reunion: '12380E9P24H',
        date: '20/12/2021',
        heure: '12H00- 14H00',
    },
];

export default LiveEventTableData;
